import { withStyles } from "@material-ui/core/styles";
import React from 'react'
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography'
import { useSelector, useDispatch } from "react-redux";

const styles = (theme) => ({
    root: {
        position: "absolute",
        top: 10,
        left: 10,
        right: 10,
        maxWidth: 300,
        width: "100%",
        margin: "auto",
        background: "#fff",
        borderRadius: 4,
        paddingLeft: 24,
        paddingTop: 8,
        paddingRight: 24,
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        [theme.breakpoints.down("md")]: {
            top: "auto",
            bottom: 80,
        }
    },
    filterText: {
        fontWeight: 600
    },
    sliderLabel: {
        "& > span  > span": {
            color: theme.palette.common.white
        }
    },
})

function valuetext(value) {
    return `${value}%`;
}

const GlobalSlider = ({
    classes
}) => {

    const { rangeFilter, gradients } = useSelector(state => state.municipalityLayer)
    const dispatch = useDispatch()

    const setRangeFilter = (range) => {
        dispatch({type: "SET_RANGE_FILTER", range})
    }

    if(gradients.find(g => g.type === "categorized" && g.active)){
        return null
    }

    return (
        <div className={classes.root}>
            <Typography variant="caption" className={classes.filterText}>Percentuálne rozpätie</Typography>
            <Slider
                step={1}
                classes={{root: classes.slider, valueLabel: classes.sliderLabel}}
                defaultValue={rangeFilter}
                onChangeCommitted={(e, newValue) => setRangeFilter(newValue)}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                valueLabelFormat={valuetext}
            />
        </div>
    )
}

export default withStyles(styles)(GlobalSlider)