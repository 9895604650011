import general from './general';
import map from './map';
import municipalityLayer from './municipalityLayer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    general,
    municipalityLayer,
    map
})

export default rootReducer