import React from 'react';
import OSMLayer from './OSMLayer';
import { useSelector } from 'react-redux';
import TileWMS from './TileWMS';
// import PoruchyGeoJsSON from './PoruchyGeoJSON';

const LayersLoader = () => {

  const layers = useSelector(state => state.map.layers)
  const baseLayers = useSelector(state => state.map.baseLayers)
  
  const base = baseLayers.map(layer => {
    if(layer.type.toLowerCase() === "osm")
      return <OSMLayer key={layer.id} {...layer} />
    if(layer.type.toLowerCase() === "tilewms")
      return <TileWMS key={layer.id} {...layer} />
    
    return null;
    
  })

  const commonLayers = layers.map(layer => {
    if(layer.type.toLowerCase() === "osm")
      return <OSMLayer key={layer.id} {...layer} />
    if(layer.type.toLowerCase() === "tilewms")
      return <TileWMS key={layer.id} {...layer} />
    
    return null;
    
  })

  return (
    <>
      {base} {commonLayers}
    </>
  )
        
}

export default LayersLoader;
