export const MUNICIPALITY_ATTR_TITLE = "nm4";

export const GRADIENT_SAS = [{
    value: 2,
    color: "#ccffbd"
},{
    value: 5,
    color: "#9af280"
},{
    value: 10,
    color: "#63d442"
},{
    value: 15,
    color: "#2f9611"
},{
    value: 100,
    color: "#1d7504"
}]

export const GRADIENT_LUDIA = [{
    value: 1,
    color: "#ffeab5"
},{
    value: 2,
    color: "#f2ca66"
},{
    value: 5,
    color: "#dbac35"
},{
    value: 10,
    color: "#bf8e11"
},{
    value: 100,
    color: "#c2720a"
}]

export const GRADIENT_BLUE_LOW = [{
    value: 1,
    color: "#c9daff"
},{
    value: 2,
    color: "#99b9ff"
},{
    value: 5,
    color: "#6896fc"
},{
    value: 10,
    color: "#3c77fa"
},{
    value: 100,
    color: "#0a46c9"
}]

export const GRADIENT_BLUE = [{
    value: 5,
    color: "#c9daff"
},{
    value: 10,
    color: "#99b9ff"
},{
    value: 15,
    color: "#6896fc"
},{
    value: 20,
    color: "#3c77fa"
},{
    value: 100,
    color: "#0a46c9"
}]

export const GRADIENT_RED = [{
    value: 5,
    color: "#ffd7d4"
},{
    value: 10,
    color: "#faa49d"
},{
    value: 15,
    color: "#fa7266"
},{
    value: 20,
    color: "#d93325"
},{
    value: 100,
    color: "#94150a"
}]

export const GRADIENT_RODINA = [{
    value: 5,
    color: "#f5cef0"
},{
    value: 10,
    color: "#f099e5"
},{
    value: 15,
    color: "#d950c7"
},{
    value: 20,
    color: "#bd1ca8"
},{
    value: 100,
    color: "#850975"
}]

export const GRADIENT_SMER = [{
    value: 10,
    color: "#ffd7d4"
},{
    value: 15,
    color: "#faa49d"
},{
    value: 20,
    color: "#fa7266"
},{
    value: 30,
    color: "#d93325"
},{
    value: 100,
    color: "#94150a"
}]

export const GRADIENT_DARKGREEN = [{
    value: 10,
    color: "#aaf2b6"
},{
    value: 15,
    color: "#65bf74"
},{
    value: 20,
    color: "#2bb542"
},{
    value: 30,
    color: "#0d8521"
},{
    value: 100,
    color: "#12521d"
}]


export const GRADIENT_LSNS = [{
    value: 2,
    color: "#e6bb81"
},{
    value: 5,
    color: "#e6a957"
},{
    value: 10,
    color: "#bd8233"
},{
    value: 15,
    color: "#a36818"
},{
    value: 100,
    color: "#6e4610"
}]

export const CATEGORIZED_RESULTS = [{
    value: "olano",
    title: "OĽANO",
    color: "#afed85"
},{
    value: "smer",
    title: "SMER - SD",
    color: "#f5b39d"
},
{
    value: "rodina",
    title: "SME RODINA",
    color: "#ffadf7"
},{
    value: "lsns",
    title: "ĽSNS",
    color: "#b5a68f"
}
,{
    value: "sas",
    title: "SaS",
    color: "#4fc95f"
},
{
    value: "zaludi",
    title: "ZA ĽUDÍ",
    color: "#ffd87d"
}
,
{
    value: "ps",
    title: "PS / SPOLU",
    color: "#8cc4f5"
}
,
{
    value: "kdh",
    title: "KDH",
    color: "#cec1e6"
},
{
    value: "mkomks",
    title: "MKO - MKS",
    color: "#ffc336"
}

,
// {
//     value: "dv",
//     title: "DOBRÁ VOĽBA",
//     color: "#ff00e6"
// },
{
    value: "sns",
    title: "SNS",
    color: "#69d7ff"
},
{
    value: "most",
    title: "MOST - HÍD",
    color: "#e6f584"
},
// {
//     value: "vlast",
//     title: "VLASŤ",
//     color: "#70675c"
// },
{
    value: "x",
    title: "Viac víťazov",
    color: "#cdcdcd"
}
]