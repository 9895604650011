import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Map from './ol/Map';
import Layout from './containers/Layout'
import LayersLoader from './ol/Layers';
import MunicipalitiesLayer from './containers/MunicipalitiesLayer';
import GeoJSONLayer from './ol/Layers/GeoJSONLayer';
import SearchInput from './containers/SearchInput'
import Attribution from './components/Attribution';
import "./app.css";
import Slider from './containers/Slider';

const App = () => (
  <Router>
    <Layout>         
        <Map>
          <LayersLoader/>
          <MunicipalitiesLayer url="/data/obec.json" />
          <GeoJSONLayer strokeWidth={0} url="/data/sr.json" visible={true} />
          <GeoJSONLayer strokeWidth={2.5} url="/data/kraj.json" visible={true} />
          <GeoJSONLayer strokeWidth={1.5} url="/data/okres.json" visible={true} />
          <SearchInput/>
        </Map>
        <Attribution>
            Vypracoval <a href="https://www.skymove.sk">SKYMOVE s.r.o.</a>, Zdroj údajov Štatistický úrad SR - <a href="https://www.volbysr.sk/sk/index.html">Voľby do Národnej rady SR 2020</a>
        </Attribution>
        <Slider/>
    </Layout>
  </Router>
)

export default App;
