import { 
    Collapse, 
    Typography, 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    ListItemSecondaryAction, 
    Switch 
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";
import React from 'react'
// import Slider from '@material-ui/core/Slider';

const styles = (theme) => ({
    legend: {
        marginTop: 8,
        marginBottom: 8,
        marginLeft: 8,
        marginRight: 8,
        padding: 8
    },
    legendVertical: {
        marginBottom: 8,
        marginLeft: 8,
        marginRight: 8,
        padding: 8
    },
    legendBox: {
        width: 50,
        height: 15
    },
    slider: {
        width: "90%",
        marginLeft: 2,
        marginTop: 12,
    },
    legendItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },   
    legendText: {
        width: 50,
        height: 20,
        paddingLeft: 4,
        fontSize: "0.9em",
        position: "relative",
        left: 14
    },
    legendHorizontalItem: {
        display: "flex",
        alignItems: "center",
        "& > div": {
            marginRight: 8,
            width: 30
        }
    },
    zeroText: {
        paddingLeft: 4,
        fontSize: "0.9em",
    },
    filterText: {
        position: "relative",
        top: 16,
    },
    listItemText: {
        paddingRight: 24
    }
})



const LayerItem = ({
    title,
    onChange,
    id,
    active,
    legendItems,
    classes,
    icon,
    rangeFilter,
    setRangeFilter,
    legendType
}) => {

   

    return (
        <>
            <ListItem button onClick={() => onChange(id)} dense={true}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText                              
                    primary={title} 
                    className={classes.listItemText}
                />
                <ListItemSecondaryAction>
                    <Switch
                        edge="end"
                        onChange={() => onChange(id)}
                        checked={active}
                    />
                </ListItemSecondaryAction>
            </ListItem> 
            {legendItems ?
            <Collapse in={active}>
                {legendType === "vertical" ?
                <div className={classes.legendVertical}>
                    {
                        legendItems.map((g, idx) => (
                            <div key={idx} className={classes.legendHorizontalItem}>
                                <div className={classes.legendBox} style={{background: g.color}}></div>
                                <Typography variant="caption">{g.title}</Typography>
                            </div>
                        ))
                    }
                </div> :
                <div className={classes.legend}>
                    <div  className={classes.legendItem}>
                    <Typography className={classes.zeroText}>0%</Typography>
                    {
                        legendItems.map((g, idx) => (
                            <Typography key={idx} className={classes.legendText}>{Math.round(g.value)}%</Typography>
                        ))
                    }
                    </div>
                    <div className={classes.legendItem}>
                    {
                        legendItems.map((g, idx) => (
                            <div key={idx} className={classes.legendBox} style={{background: g.color}}></div>
                        ))
                    }
                    </div>
                    {/* <Typography variant="caption" className={classes.filterText}>Filter</Typography>
                    <Slider
                        step={1}
                        classes={{root: classes.slider, valueLabel: classes.sliderLabel}}
                        defaultValue={rangeFilter}
                        onChangeCommitted={(e, newValue) => setRangeFilter(newValue)}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        valueLabelFormat={valuetext}
                    /> */}
                </div>
                }
            </Collapse> : null
            }   
        </>
    )
}

export default withStyles(styles)(LayerItem)