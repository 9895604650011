
const initState = {
    baseLayers: [
        {
            id: 1,
            title: "OpenStreetMap",
            type: "osm",
            baselayer: true,
            visible: false,
            image: "icon_1.png"
        },
        // {
        //     id: 2,
        //     title: "Ortofotomapa SR",
        //     type: "tilewms",
        //     baselayer: true,
        //     url: "https://zbgisws.skgeodesy.sk/zbgis_ortofoto_wms/service.svc/get?",
        //     visible: true,
        //     image: "icon_2.png",
        //     params: {
        //         LAYERS: "0,1",
        //         FORMAT: "image/jpeg"
        //     }
        // },
        // {
        //     id: 3,
        //     title: "ZBGIS",
        //     type: "tilewms",
        //     baselayer: true,
        //     image: "icon_3.png",
        //     url: "https://zbgisws.skgeodesy.sk/zbgis_wms_featureinfo/service.svc/get",
        //     visible: true,
        //     params: {
        //         LAYERS: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,95,97,98,99,100,102,103,104,105,107,109,110,111,112,114,115,116,117,118,119,121,122,123,124,125,126,128,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,148,150,152,153,154,155,156,157,159,160,161,162,163,164,166",
        //         FORMAT: "image/png"
        //     }
        // },
    ],
    layers: [
        // {
        //     id: 1,
        //     title: "KN - Určený operát",
        //     type: "tilewms",
        //     url: "https://kataster.skgeodesy.sk/eskn/services/NR/uo_wms_norm/MapServer/WmsServer?",
        //     visible: false,
        //     params: {
        //         LAYERS: "0,1,2,3,4,5",
        //         FORMAT: "image/png"
        //     }
        // },
        // {
        //     id: 2,
        //     title: "KN - Katastrálna mapa",
        //     type: "tilewms",
        //     url: "https://kataster.skgeodesy.sk/eskn/services/NR/kn_wms_norm/MapServer/WmsServer",
        //     visible: false,
        //     params: {
        //         LAYERS: "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15",
        //         FORMAT: "image/png"
        //     }
        // }
    ],
    view: {
        center: [19.2, 48.7],
        zoom: 8,
        minZoom: 7,
        maxZoom: 10
    }
}



const map = (state = initState, action) => {
    switch (action.type) {
        case 'SET_BASELAYER_VISIBILITY':
            return {
                ...state,
                baseLayers: state.baseLayers.map(bl => ({
                    ...bl,
                    visible: bl.id === action.id ? true : false
                }))
            }
        case 'SET_LAYER_VISIBILITY':
            return {
                ...state,
                layers: state.layers.map(l => ({
                    ...l,
                    visible: l.id === action.id ? !l.visible : l.visible
                }))
            }
        case 'SET_LAYERS':
            return {
                ...state,
                layers: action.layers
            }
        case 'ADD_LAYER':
            return {
                ...state,
                layers: [
                    ...state.layers, 
                    action.layer
                ]
            }
        case 'REMOVE_LAYER':
            return {
                ...state,
                layers: state.layers.filter(l => l.id !== action.id)
            }
        default:
            return state
    }
}

export default map;