import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles, CardHeader, Grid, IconButton } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PieGraph from '../components/PieGraph'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    table: {

    },
    closeButton: {
        top: 8,
        right: 8,
        left: "auto",
        position: "absolute"
    }
}))

const InfoDialog = () => {

    const { identification: {
        open,
        attributes,
        title
    } } = useSelector(state => state.municipalityLayer)
    const dispatch = useDispatch()
    const classes = useStyles()

    const closeDialog = () => {
        dispatch({type: "CLOSE_IDENTIFICATION_DIALOG"})
    }

    function compare( a, b ) {
        if ( a.value < b.value ){
          return 1;
        }
        if ( a.value > b.value ){
          return -1;
        }
        return 0;
      }

    attributes.sort(compare)

    return (
        <Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open} className={classes.root}>   
            <IconButton className={classes.closeButton} onClick={closeDialog}>
                <CloseIcon />
            </IconButton>         
            <Grid container>
                <Grid item xs={12}>
                <TableContainer>
                    <CardHeader title={title}/>
                    <Table size="small" className={classes.table} aria-label="simple table">
                        <TableBody>
                            {
                                attributes.map((attr) => (
                                    attr.value ? <TableRow key={attr.id}>
                                        <TableCell component="th" scope="row">
                                        {attr.title}
                                        </TableCell>
                                        <TableCell align="right">{attr.value ? `${attr.value}%` : ""}</TableCell>
                                    </TableRow> : null
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
                    <Grid item xs={12}>
                        <PieGraph data={attributes.map(a => ({name: a.title, value: a.value, color: a.color}))}/>
                    </Grid>
                </Grid>
        </Dialog>
    )

}

export default InfoDialog