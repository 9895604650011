import { 
    GRADIENT_BLUE,
    GRADIENT_RED,
    GRADIENT_SMER,
    GRADIENT_SAS,
    GRADIENT_BLUE_LOW,
    GRADIENT_LSNS,
    GRADIENT_DARKGREEN,
    CATEGORIZED_RESULTS,
    GRADIENT_RODINA,
    GRADIENT_LUDIA
} from "../constants"

const initState = {
    visible: true,
    search: "",
    searchResults: [],
    mode: "gradient",
    rangeFilter: [0,100],
    identification: {
        open: false,
        title: "",
        attributes: [{
            id: "smer",
            title: "SMER - SD",
            value: null,
            color: "#f5b39d",
        }, {
            id: "sas",
            title: "SaS",
            value: null,
            color: "#4fc95f",
        },{
            id: "olano",
            title: "OĽANO",
            value: null,
            color: "#afed85",
        },{
            id: "sns",
            title: "SNS",
            value: null,
            color: "#122aff",
        },{
            id: "rodina",
            title: "SME RODINA",
            value: null,
            color: "#ffadf7",
        },{
            id: "kdh",
            title: "KDH",
            value: null,
            color: "#676bdb",
        },{
            id: "lsns",
            title: "ĽSNS",
            value: null,
            color: "#6f8a6e",
        },{
            id: "most",
            title: "MOST - HÍD",
            value: null,
            color: "#e6f584",
        },{
            id: "ps",
            title: "PS / SPOLU",
            value: null,
            color: "#8cc4f5",
        },{
            id: "zaludi",
            title: "ZA ĽUDÍ",
            value: null,
            color: "#ffd87d",
        },{
            id: "mkoms",
            title: "MKO - MKS",
            value: null,
            color: "#ffc336",
        },{
            id: "vlast",
            title: "VLASŤ",
            value: null,
            color: "#70675c",
        },{
            id: "dv",
            title: "Dobrá voľba",
            value: null,
            color: "#ff00e6",
        },{
            id: "sls",
            title: "SĽS",
            value: null,
            color: "#8f1391",
        },{
            id: "sho",
            title: "SHO",
            value: null,
            color: "#9994d1",
        },{
            id: "dost",
            title: "MÁME TOHO DOSŤ",
            value: null,
            color: "#a81b74",
        },{
            id: "ds",
            title: "DS",
            value: null,
            color: "#0e1c47",
        },{
            id: "solidarita",
            title: "Solidarita",
            value: null,
            color: "#3babbf",
        },{
            id: "stank",
            title: "STANK",
            value: null,
            color: "#3babbf",
        },{
            id: "sl",
            title: "Slovenská liga",
            value: null,
            color: "#d5a1ff",
        },{
            id: "hlasludu",
            title: "Hlas ľudu",
            value: null,
            color: "#ffadf4",
        },{
            id: "psn",
            title: "PSN",
            value: null,
            color: "#abbf3b",
        },{
            id: "socialisti",
            title: "Socialisti.sk",
            value: null,
            color: "#ffadf4",
        },{
            id: "devatdevat",
            title: "99% - Občiansky hlas",
            value: null,
            color: "#2aeb5a",
        }]
    },
    gradients: [
        {
            id: "results",
            title: "Výsledky volieb podľa obcí",
            active: true,
            attr: "won",
            type: "categorized",
            style: CATEGORIZED_RESULTS
        },
        {
            id: "olano",
            title: "OĽANO",
            active: false,
            attr: "olano",
            style: GRADIENT_DARKGREEN
        },
        {
            id: "smer",
            title: "SMER - SD",
            active: false,
            attr: "smer",
            style: GRADIENT_SMER
        },
        {
            id: "rodina",
            title: "SME RODINA",
            active: false,
            attr: "rodina",
            style: GRADIENT_RODINA
        },
        {
            id: "lsns",
            title: "ĽSNS",
            active: false,
            attr: "lsns",
            style: GRADIENT_LSNS
        },
        {
            id: "sas",
            title: "SaS",
            active: false,
            attr: "sas",
            style: GRADIENT_SAS
        },
        {
            id: "zaludi",
            title: "ZA ĽUDÍ",
            active: false,
            attr: "zaludi",
            style: GRADIENT_LUDIA
        },
        {
            id: "ps",
            title: "PS / SPOLU",
            active: false,
            attr: "ps",
            style: GRADIENT_BLUE_LOW
        },
        {
            id: "kdh",
            title: "KDH",
            active: false,
            attr: "kdh",
            style: GRADIENT_BLUE
        },
        {
            id: "dv",
            title: "Dobrá voľba",
            active: false,
            attr: "dv",
            style: GRADIENT_RODINA
        },
        {
            id: "vlasť",
            title: "VLASŤ",
            active: false,
            attr: "vlast",
            style: GRADIENT_LSNS
        },
        {
            id: "sns",
            title: "SNS",
            active: false,
            attr: "sns",
            style: GRADIENT_BLUE_LOW
        },
        {
            id: "most",
            title: "MOST - HÍD",
            active: false,
            attr: "most",
            style: GRADIENT_RED
        },
        {
            id: "mkoms",
            title: "MKO - MKS",
            active: false,
            attr: "mkoms",
            style: GRADIENT_BLUE
        }
    ],
    filter: [
        
    ]
    
}

const adaptString = (str) => {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const municipalityLayer = (state = initState, action) => {
    switch (action.type) {
        case 'SEARCH':
            return {
                ...state,
                search: adaptString(action.search)
            }
        case 'SET_SEARCH_RESULTS':
            return {
                ...state,
                searchResults: action.results
            }
        case 'SET_RANGE_FILTER':
            return {
                ...state,
                rangeFilter: action.range
            }
        case 'SET_MUNICIPALITY_VISIBLE':
            return {
                ...state,
                visible: action.visible
            }
        case 'CLOSE_IDENTIFICATION_DIALOG':
            return {
                ...state,
                identification: { ...state.identification, open: false}
            }
        case 'SET_IDENTIFICATION':
            let identification = state.identification;
            const attr = state.identification.attributes.map(f => {
                const entry = Object.entries(action.feature.getProperties()).find(([key]) => key === f.id);
                debugger
                return {
                    ...f,
                    value: entry.length > 1 ? entry[1] : null
                }
            })
            identification.attributes = attr;
            return {
                ...state,
                identification: { ...identification, title: action.feature.get("nm4"), open: true }
            }
        case 'SET_ACTIVE_GRADIENT': 
            const gradients = state.gradients.map(g => ({
                ...g,
                active: action.id === g.id ? !g.active : false
            }))
            
            const isAnyActiveGradient = gradients.find(g => g.active === true);

            return {
                ...state,
                gradients,
                // rangeFilter: [0,100],
                mode: isAnyActiveGradient ? "gradient" : "filter",
                filter: state.filter.map(f => ({
                    ...f,
                    active: false
                })),
            }
        case 'ACTIVE_MUNICIPALITY_FILTER':
            return {
                ...state,
                filter: state.filter.map(f => ({
                    ...f,
                    active: f.id === action.id ? action.active : f.active
                })),
                mode: "filter",
                gradients: state.gradients.map(g => ({...g, active: false}))
            }
        default:
            return state
    }
}

export default municipalityLayer;