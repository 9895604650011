import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
   const x = cx + radius * Math.cos(-midAngle * RADIAN);
   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    (value > 5) ? <text font-size="0.6em" x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {name}
    </text> : null
  );
};

export default class Example extends PureComponent {

  render() {
      const {data} = this.props;
    return (
        <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer>
      <PieChart>
        <Pie
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={90}
          outerRadius={110}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
          }
        </Pie>
      </PieChart>
      </ResponsiveContainer>
      </div>
    );
  }
}
